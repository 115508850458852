/*variables*/
:root {
  --primary-color: #00AB66;
  --primary-color-dark: #003b23;
  --primary-color-light: #acffde;
  --secondary-color: #0033F2;
  --scrollbar-color: #6E7878;
  background-color: #F5F5F5;
}

/*CSS Base body*/
body {
  font-family: Arial, Roboto, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
}

/*Scroll bar*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-color);
}

::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-color);
}

::-webkit-scrollbar-track {
  background: rgba(9,30,66,.08);
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: rgba(9,30,66,.08);
}

::-webkit-scrollbar-track:active {
  background: rgba(9,30,66,.08);
}

::-webkit-scrollbar-corner {
  background: transparent;
}